.cookie-modal .modal-header {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .cookie-bar {
        text-align: center;
        padding: 1rem 1.5rem;
    }
}