#table-of-content {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
}
#table-of-content a {
    color:#fff;
    display: inline-block;
    padding: .5rem;
    background: var(--color-primary);
}