.footer{
    padding: 1.5rem 2rem;
    background:#0B0B0C;
    color:#BEC2C6;
    font-family:var(--font-default);
    font-size: 1rem;
    @media (max-width: 767px) {
        padding: 2rem;
        text-align:center;
    }
}
footer a:not(.btn):hover,
footer a:not(.btn):focus,
.footer .active-lang{
    color:#fff;
}