.tiles {
    position: relative;
    margin-top: 1.5rem;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
    box-shadow: 0 15px 30px 0 rgba(11,11,12,0.1);
}
.tiles:after,
.tiles:before {
    content:'';
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width:216px;
    background:url(/labs/static/img/bg_tiles_right.png) 100% 100% no-repeat;
    background-size: cover;
    z-index:1;
    opacity:0.5;
    @media screen and (max-width: 767px) {
        width:auto;
        left:0;
    }
}
.tiles:after {
    left:0;
    width:220px;
    background:url(/labs/static/img/bg_tiles_left.png) 0 100% no-repeat;
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.tiles__body {
    position: relative;
    z-index: 5;
}
@media screen and (min-width: 768px) {
    .tiles .row + .row {
        border-top:1px solid #fff;
    }
}
.tiles__item {
    display: block;
    height:auto;
    color:#fff;
    border-right:1px solid #fff;
    font-family: var(--font-default-bold);
    padding:2rem 1rem 2rem 2rem;
    font-size:1.25rem;
    line-height:1.1;
    @media screen and (min-width: 768px) {
        min-height:11rem;
        height:100%;
        font-size:1.5rem;
    }
}
.tiles__col:last-child .tiles__item {
    border-right:0;
}
@media screen and (max-width: 767px) {
    .tiles__item {
        border-right:0;
        border-bottom:1px solid #fff;
    }
}
.tiles__item a {
    font-size: .875rem;
    padding-bottom:1px;
    display: inline-block;
}
.tiles__item a:hover,
.tiles__item a:focus {
    border-bottom:2px solid #fff;
}