.btn{
    padding: 1rem 2.5rem;
    font-family: var(--font-default-bold);
    letter-spacing: .15rem;
    font-size: 1.125rem;
    line-height: 1;
    border:0;
}
.btn .icon{
    font-size: 1rem;
    position: relative;
    top:1px;
    margin-right:.125rem;
}
.btn-primary{
    color: #fff;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
}
.btn-primary:hover,
.btn-primary:focus{
    color: #fff;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 50%, #7f21d9 100%);
}