.chart {
    font-size:1rem;
    height:540px;
    font-family: var(--font-default-bold);
    overflow:hidden;

    @media screen and (max-width: 767px) {
        min-width:850px;
        padding-right:1rem;
    }
}
.chart-scroll {
    @media screen and (max-width: 767px) {
        position:relative;
        overflow-x:scroll;
        margin:0 -2rem;
    }
}
.chart-legend {
    position: relative;
}
.chart-legend__item {
    height:60px;
    position: relative;
}
.chart-legend__item:before {
    content:'';
    position: absolute;
    left:110%;
    top:10px;
    width: 2000px;
    height:1px;
    background: #E7E7E7;
}
.chart__item {
    position: relative;
    margin:0 auto 10px auto;
    width: 2rem;
    @media screen and (min-width: 1200px) {
        width: 3rem;
    }
}
.chart__bar {
    margin:0 auto 1rem auto;
    position: relative;
    background: linear-gradient(190deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
}
.chart__year {
    position: relative;
    width: 3.5rem;
    transform: rotate(-45deg);
}
.chart__value {
    position: relative;
    white-space: nowrap;
    margin-bottom:1.25rem;
    transform: rotate(-90deg);
}