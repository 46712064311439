.slide {
    padding-top: calc(45rem/16);
    padding-bottom: calc(45rem/16);
}
.slide.slide--no-pt {
    padding-top:0;
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(90rem/16);
        padding-bottom: calc(90rem/16);
    }
}