/*
.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}*/

/*quick fix for large whitespace at end of page on ios*/
@media screen and (max-width: 767px) {
    .pimcore_area_content {
        position: relative;
        overflow:hidden;
    }
}