/**
 * @license
 * MyFonts Webfont Build ID 3923230, 2020-07-08T03:10:22-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: DINCondensed-Regular by ParaType
 * URL: https://www.myfonts.com/fonts/paratype/din-condensed/pt-din-condensed-40316/
 * Copyright: Copyright &amp;#x00A9; 1998 ParaType Inc., ParaType Ltd. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3cc57d");
@import url("//hello.myfonts.net/count/3cc57c");

@font-face {
    font-family: 'FFDINWebProBold';
    src: url('/static/fonts/FFDINWebProBold.woff2') format('woff2'),
    url('/static/fonts/FFDINWebProBold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'FFDINWebProRegular';
    src: url('/static/fonts/FFDINWebProRegular.woff2') format('woff2'),
    url('/static/fonts/FFDINWebProRegular.woff') format('woff');
    font-display: swap;
}