.logoblock {
    padding:calc(50rem / 16) calc(80rem / 16);
    background-color: #FFFFFF;
    box-shadow: 0 10px 10px 0 rgba(11,11,12,0.1);
    @media (max-width: 767px) {
        padding:calc(1rem / 16) 0;
    }
}
.logoblock__img {
    width: auto;
    height:1.5rem;
    @media screen and (min-width: 992px) {
        height:2rem;
    }
    @media screen and (min-width: 1600px) {
        height:2.5rem;
    }
}
.logoblock__thumb {
    margin:0 1rem .5rem 0;
    @media screen and (max-width: 991px) {
        margin:2rem;
    }
}

.logoblock__wysiwyg {
    margin:3.5rem 0 1.5rem 0;
    @media screen and (max-width: 767px) {
        margin:1.5rem 0;
    }
}