@media (min-width: 992px) {
    .modal--lab-details {
        max-width: 1200px;
    }
}
.modal-content {
    box-shadow: 0 0 155px 0 rgba(11,11,12,0.5);
}
.modal-body {
    padding: 2rem 2rem 3rem 2rem;
}
.modal-backdrop.show {
    opacity: 0;
}
.modal-header {
    padding: 3rem 7rem 0rem 2rem;
    @media (max-width: 767px) {
        padding-right:5rem;
    }
}
.cookie-modal .close.close,
.modal__close {
    position: absolute;
    right:0;
    top:0;
    padding:1rem 1.5rem;
    margin: 0;
    opacity: 1;
    float: none;
    border:0;
    color:#fff;
    display: inline-block;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 57.68%, #ED389D 100%);
}
.cookie-modal .close.close:hover,
.cookie-modal .close.close:focus,
.modal__close:hover,
.modal__close:focus {
    opacity: 1;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 35%, #ED389D 100%);
}
.cookie-modal .close>span,
.modal__close>span {
    font-size: 2rem;
    line-height:1;
}