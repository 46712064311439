html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
    scroll-behavior: smooth;
}
body {
    position: relative;
    background:url(/labs/static/img/bg_desktop_1920.jpg) #FAFAFB 100% 0 no-repeat;
    @media screen and (max-width: 767px) {
        background:url(/labs/static/img/bg_mobile.jpg) #FAFAFB 100% 0 no-repeat;
        background-size:200px 126px;
    }
    @media screen and (min-width: 768px) and (max-width: 1600px) {
        background-size: contain;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(960rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-left:2rem;
        padding-right:2rem;
    }
}
.container.container--wide {
    max-width: calc(1460rem/16);
}
.container.container--medium {
    max-width: calc(1330rem/16);
}