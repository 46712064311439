@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1629803399967/labs/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1629803399967/labs/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1629803399967/labs/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arrow-left:before { content: "\EA01" }
.icon-arrow-right:before { content: "\EA02" }
.icon-check:before { content: "\EA03" }
.icon-elements:before { content: "\EA04" }
.icon-facebook:before { content: "\EA05" }
.icon-left-arrow:before { content: "\EA06" }
.icon-mail:before { content: "\EA07" }
.icon-right-arrow:before { content: "\EA08" }


:root {
--icon-arrow-left: "\EA01";
    --icon-arrow-right: "\EA02";
    --icon-check: "\EA03";
    --icon-elements: "\EA04";
    --icon-facebook: "\EA05";
    --icon-left-arrow: "\EA06";
    --icon-mail: "\EA07";
    --icon-right-arrow: "\EA08";
    
}