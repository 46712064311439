.success-numbers__item {
    margin:2rem 0 0 0;
    @media screen and (min-width: 768px) {
        margin:4.5rem 0  0;
    }
}
.success-numbers__item:last-of-type {
    @media screen and (max-width: 767px) {
        margin-bottom:2.5rem;
    }
}


/* chart */
.numbers-chart {
    position: relative;
    height: 30rem;
    width: 6.25rem;
    background: linear-gradient(190deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
    margin: 2rem auto 1rem auto;

    @media screen and (min-width: 768px) {
        height: 50rem;
        margin: 0 auto;
    }
}
.numbers-chart__lbl {
    font-size: 1rem;
    font-family: var(--font-default-bold);
    position: absolute;
    left: -6.125rem;
    right: -6.5rem;
    top:-2rem;

    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
    }
}
.numbers-chart__value {
    font-size: 1.125rem;
    font-family: var(--font-default-bold);
    position: absolute;
    left: -6.125rem;
    right: -6.5rem;

    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}
.numbers-chart__value:before {
    content:'';
    width: 6.5rem;
    height:2px;
    background: #fff;
    opacity: .5;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translateY(-50%);
    margin-left:-3.5rem;
}