.main-navbar {
    position: relative;
    /*position: fixed;
    top: 0;
    left: 0;
    right: 0;*/
    color: white;
    z-index: 100;
    padding-top: 1.375rem;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.main-navbar__container {
    width: 100%;
    max-width:1920px;
    padding:0 2rem;
}

.main-navbar__brand {
    position: absolute;
    left:2rem;
    top:1.5rem;
    max-width: calc(270rem/16);
    @media (min-width: 1024px) and (max-width: 1365px) {
        max-width: calc(200rem/16);
    }
    @media (max-width: 767px) {
        max-width: calc(130rem/16);
    }
}

.main-navbar__nav {
    list-style: none;
    @media (max-width:1023px) {
        display:none;
    }
    @media (min-width: 1024px) and (max-width: 1600px) {
        margin-left:3rem;
    }
}

.main-navbar__nav-item {
    display: inline-block;
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem/16);
    text-transform: uppercase;
    padding: 0 1.5rem;
    font-size: calc(18rem/16);
    @media (max-width: 1600px) {
        padding: 0 .5rem;
    }
}

.main-navbar__nav-link {
    position: relative;
    display: inline-block;
    padding: 1rem 0 .75rem 0;
}

.main-navbar__nav-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: white;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out;
    @media (min-width: 768px) {
        bottom: calc(8rem/16);
    }
}

.main-navbar__nav-link.active:before,
.main-navbar__nav-link:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.main-navbar__contact {
    position: absolute;
    right:2rem;
    top:1.5rem;
    @media (max-width: 767px) {
        display:none;
    }
}

.main-navbar__contact-icon {
    @media (max-width: 1439px) {
        display:none;
    }
}