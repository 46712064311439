/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-transform-none {text-transform: none}
.spacing-none.spacing-none { letter-spacing: 0 }

/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.gradient-text, .text-gradient, .title-block__title b, .title-block__title strong {
    background-image: linear-gradient(60deg, #9942D9, #19D0EA);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        background:transparent !important;
    }
}

/* Background Color Helper */
.bg-lightgrey {
    background:#FAFAFB;
}
.bg-white {
    background:#fff;
}
.isCursor{
    cursor: pointer;
}


/* Additional */
.btn-no-styling{
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}