.references-slider {

}
.references-slider__item {
    text-align: center;
    color:#D1D1D1;
    padding:0 2rem;
    font-size: 1rem;
    line-height:1.33;
}
.references-slider__item-img {
    display: block;
    margin:0 auto 1rem auto;
    width: 100%;
    height:auto;
    max-width:calc(150rem / 16);
}