.contact-form {
    position: relative;
    width: 100%;
    max-width:480px;
}
.form-control-wrapper {
    padding:2px;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
}
.form-control-wrapper--narrow {
    width:100%;
    max-width:14rem;
}
.form-control {
    border:0;
    color:var(--color-text-default);
    font-size:1.125rem;
    letter-spacing: 1px;
    height: 3.5rem;
    padding: .875rem 1.125rem;
    @media screen and (max-width: 767px) {
        font-size:1rem;
    }
}
.form-control::-webkit-input-placeholder { /* Edge */
    color:var(--color-text-default);
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:var(--color-text-default);
}
.form-control::placeholder {
    color:var(--color-text-default);
}