.slider:not(.slick-slider)>div+div{
    display: none;
}
.slider {
    position: relative;
    margin: 0;
}
.slider--hide-dots .slick-dots{
    display: none !important;
}





/* basic arrows */
.slider .slick-arrow {
    position: absolute;
    right:-1rem;
    top:50%;
    transform: translateY(-50%);
    color: transparent;
    background: transparent;
    width:2.5rem;
    height:2.5rem;
    border-radius:0;
    line-height: 0;
    cursor: pointer;
    z-index: 90;
    border:0;
    outline:0 !important;
    opacity:.8;
    font-size:0;
    @media screen and (min-width: 1500px) {
        right:-3rem;
    }
}
.slider .slick-arrow>.icon {
    font-size:1.25rem;
    display: block;
    position: absolute;
    left:50%;
    top:50%;
    color: #fff;
    transform: translateX(-50%) translateY(-50%);
}
.slider .slick-arrow.slick-prev>.icon {
    content:var(icon-right-arrow) !important;
}

.slider .slick-arrow.slick-disabled {
    opacity: .33;
    cursor: not-allowed;
}

.slider .slick-arrow.slick-prev {
    right:auto;
    left:-1rem;
    @media screen and (min-width: 1500px) {
        right:auto;
        left:-3rem;
    }
}

.slider .slick-arrow:not(.slick-disabled):hover,
.slider .slick-arrow:not(.slick-disabled):focus {
    opacity: 1;
}