.fancyblock {
    position: relative;
    color:#fff;
    background:#0B0B0C;
}
.fancyblock__body {
    position: relative;
    z-index:5;
}
.fancyblock:after,
.fancyblock:before {
    content:'';
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width:500px;
    background:url(/labs/static/img/bg_fancyimg_right.png) 100% 100% no-repeat;
    background-size: cover;
    z-index:1;
    @media screen and (max-width: 767px) {
        width:auto;
        left:0;
        background-position:100% 100%;
    }
}
.fancyblock:after {
    left:0;
    background:url(/labs/static/img/bg_fancyimg_left.png) 0 100% no-repeat;
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.fancyblock a:not(.btn) {
    color:#fff;
    text-decoration: underline;
}
.fancyblock__img {
    position: absolute;
    right:0;
    bottom:0;
    display: block;
    height: auto;
    width:28vw;
    z-index:10;
    @media screen and (max-width: 1199px) {
        display:none;
    }
    @media screen and (min-width: 1780px) {
        width:26vw;
    }
}
.fancyblock__endtitle {
    margin-top:3.5rem;
    @media screen and (max-width: 767px) {
        margin-top:1.5rem;
    }
}


/* left image */
.fancyblock--left-img .fancyblock__img {
    right:auto;
    left:0;
}